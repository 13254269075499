<template>
  <basic-block :title="currentTerm.termName">
    <template #search>
      <el-row :gutter="24">
        <el-col :span="5">
          <el-select :size="$publicConfig.selectSize" v-model="search.gradeId" placeholder="请选择年级" @change="dimensionChange">
            <el-option v-for="item in allGrade" :key="item.gradeCode" :label="item.grade" :value="item.gradeCode">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select v-model="search.classCode" :size="$publicConfig.selectSize" placeholder="请选择班级" @change="dimensionChange">
            <el-option v-for="item in allClass" :key="item.classCode" :label="item.className" :value="item.classCode">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="9">
          <el-date-picker v-model="search.date" :size="$publicConfig.selectSize" type="daterange" range-separator="至" start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']" value-format="timestamp" @change="dimensionChange" end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="5">
          <el-select :size="$publicConfig.selectSize" v-model="dimensions" placeholder="请选择维度" @change="dimensionChange">
            <el-option v-for="item in dimension" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <el-row :gutter="24" v-show="dimensions == 1">
      <el-col :span="10" style="height: 200px">
        <bar :barData="countState"></bar>
      </el-col>

      <el-col :span="14">
        <el-table :data="tableData" style="width: 100%" :height="height" stripe>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="studentId" label="学号"></el-table-column>
          <el-table-column prop="name" label="考勤率">
            <template scope="scope">

              <span v-if="!scope.row.actually || !scope.row.should">
                0.00%
              </span>

              <span v-else>
                {{
                  ( (Number(scope.row.actually)  / Number(scope.row.should))* 100).toFixed(2) +
                  "%"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="should" label="应考勤次数"></el-table-column>
          <el-table-column prop="actually" label="实考勤次数"></el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="goto(scope.row)">查看详情</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-row :gutter="24" v-show="dimensions == 2">
      <el-col :span="24" style="height: 440px">
        <div id="grade_statistic" class="charts"></div>
      </el-col>
      <el-col :span="24">
        <el-table :data="tableData1" style="width: 100%" :height="height" stripe>
          <el-table-column prop="name" label="节次">
            <template scope="scope">
              第{{ scope.row.periodId + 1 }}节课
            </template>
          </el-table-column>
          <el-table-column prop="name" label="考勤率">
            <template scope="scope">
              <span>
                {{
                  scope.row.should > 0
                    ? ((scope.row.actually / scope.row.should) * 100).toFixed(
                        2
                      ) + "%"
                    : "0.00%"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="should" label="应考勤次数"></el-table-column>
          <el-table-column prop="actually" label="实考勤次数"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </basic-block>
</template>

<script>
import { studentCheckRecordStatisticsStudent } from "@/http/analysis";
import Bar from "@/views/attendance/analysis/components/bar";
import { mapGetters } from "vuex";
import { getSchoolTerm } from "@/http/leaveSchool";
import {
  studentCheckRecordStatistics,
  studentCheckRecordStatisticsClass,
} from "@/http/analysis";
let echarts = require("echarts");

export default {
  data() {
    return {
      barData: {},
      search: {
        gradeId: "",
        classCode: "",
        date: [
          new Date(this.$cftY().split(" ")[0] + " 00:00:00").getTime(),
          new Date(this.$cftY().split(" ")[0] + " 23:59:59").getTime(),
        ],
      },
      tableData: [],
      height: 100,
      currentTerm: {},
      countState: {
        late: 0,
        leave: 0,
        notArrive: 0,
        onTime: 0,
        totalCount: 0,
        studentAmount: 0,
      },
      dimensions: 1,
      classCountState: {
        late: [],
        leave: [],
        notArrive: [],
        onTime: [],
      },
      dimension: [
        {
          label: "学生维度",
          value: 1,
        },
        // {
        //   label: "课节维度",
        //   value: 2,
        // },
      ],
      gradeStatisticEchart: null,
      tableData1: [],
    };
  },
  components: {
    Bar,
  },
  computed: {
    ...mapGetters(["userInfo", "allNGrade", "allNClass", "token"]),
    allGrade() {
      let allGrade = []
      this.allNGrade.forEach(item=>{
        item.gradeList.forEach(items=>{
          if(items.gradeCode == this.search.gradeId){
            this.search.campusCode = item.campusCode
            allGrade = item.gradeList
          }
        })
      })
      console.log(allGrade,'allGrade')
      return allGrade
    },
    allClass() {
      let classArr = []
      this.allGrade.forEach(item=>{
        console.log()
        console.log(item)
        item.classList.forEach(items=>{
          if(items.classCode == this.search.classCode){
            classArr = item.classList
          }
        })
      })
      return classArr
    },
  },
  mounted() {},
  created() {
    this.search.gradeId = this.$route.query.gradeId;
    this.$set(this.search, "classCode", this.$route.query.classCode);
    let params = {
      schoolId: this.userInfo.tnId,
      termId: 0,
    };
    getSchoolTerm(params).then((res) => {
      this.currentTerm = res.data;
    });
    this.height = document.body.clientHeight - 300;
    this.studentCheckRecordStatisticsStudent();
  },
  methods: {
    studentCheckRecordStatisticsStudent() {
      let search = JSON.parse(JSON.stringify(this.search));
      search.schoolId = this.userInfo.tnId;
      studentCheckRecordStatisticsStudent(search).then((res) => {
        console.log(res,'res')
        this.tableData = res.data;
      });

      let search1 = JSON.parse(JSON.stringify(this.search));
      search1.schoolId = this.userInfo.tnId; 
      search1.classCodes = [search1.classCode];
      if (search1.gradeId) {
        search1.gradeIds = [search1.gradeId];
      }

      studentCheckRecordStatistics(search1).then((res) => {
        this.countState = {
          late: 0,
          leave: 0,
          notArrive: 0,
          onTime: 0,
          totalCount: 0,
          studentAmount: 0,
        };
        res.data.forEach((item) => {
          this.countState.late += item.late;
          this.countState.leave += item.leave;
          this.countState.notArrive += item.notArrive;
          this.countState.onTime += item.onTime;
          this.countState.totalCount += item.totalCount;
          this.countState.studentAmount += item.studentAmount;
        });
      });
    },
    goto(row) {
      this.$router.push({
        path: "/attendance/studentStatistic",
        query: {
          ...row,
          search : JSON.stringify(this.search)
        },
      });
    },
    studentCheckRecordStatisticsClass() {
      this.classCountState = {
        late: [],
        leave: [],
        notArrive: [],
        onTime: [],
      };
      let search1 = JSON.parse(JSON.stringify(this.search));
      search1.schoolId = this.userInfo.tnId;
      search1.classCodes = [search1.classCode];
      if (search1.gradeId) {
        search1.gradeIds = [search1.gradeId];
      }

      studentCheckRecordStatisticsClass(search1).then((res) => {
        this.tableData1 = res.data.filter((item) => item.checkInType == 5);

        this.tableData1.forEach((item) => {
          this.classCountState.late.push(item.late || 0);
          this.classCountState.leave.push(item.leave || 0);
          this.classCountState.notArrive.push(item.notArrive || 0);
          this.classCountState.onTime.push(item.onTime || 0);
        });
        this.setGradeStatisticEchart();
      });
    },
    dimensionChange() {
      if (this.dimensions == 1) {
        this.studentCheckRecordStatisticsStudent();
      } else {
        setTimeout(() => {
          if (!this.gradeStatisticEchart) {
            this.gradeStatisticEchart = echarts.init(
              document.getElementById("grade_statistic")
            );
          }

          this.studentCheckRecordStatisticsClass();
        }, 500);
      }
    },
    setGradeStatisticEchart() {
      // getGradeStatistic(() => {});
      let data = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: this.$publicConfig.attendanceType,
          bottom: 0,
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "40",
          top: "10",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.tableData1.map((item) => {
              return `第${item.periodId + 1}节课`;
            }),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: this.$publicConfig.attendanceType[0],
            type: "bar",
            stack: "广告",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[0],
            },
            data: this.classCountState.onTime,
          },
          {
            name: this.$publicConfig.attendanceType[1],
            type: "bar",
            stack: "广告",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[1],
            },
            barMaxWidth: 40,
            data: this.classCountState.late,
          },
          {
            name: this.$publicConfig.attendanceType[2],
            type: "bar",
            stack: "广告",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[2],
            },
            data: this.classCountState.leave,
          },
          {
            name: this.$publicConfig.attendanceType[3],
            type: "bar",
            stack: "广告",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[3],
            },
            data: this.classCountState.notArrive,
          },
        ],
      };
      this.gradeStatisticEchart.setOption(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 391px;
}
</style>